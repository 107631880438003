<template>
  <div v-if="houseData">
    <Header :navbg='true'></Header>
    <div class="content">
      <div class="banner">
        <img :src="houseData.bannerList[0].image" alt="">
        <div class="title-warp">
          <div class="title">小院·未来城{{houseType}}㎡</div>
          <!-- <div class="area"> <span>6000</span> 元/平</div> -->
        </div>
      </div>

      <div class="basic page">
        <table border="1">
          <tr>
            <th colspan="2">基础信息</th>
          </tr>
          <tr v-for="(basic,index) of basicList" :key="index">
            <td v-for="(item,index2) of basic" :key="index2">{{item}}</td>
          </tr>
        </table>
      </div>

      <div class="house-album">
        <div class="page">
          <div class="title">户型相册 <span class="more">查看更多</span> </div>
          <div class="house-list">
            <div class="item" v-for="(item,index) of houseData.photoAlbum" :key="index">
              <img :src="item.image" alt="">
              <div class="area">{{item.title}}</div>
            </div>
          </div>
        </div>

      </div>

      <div class="vr">
        <div class="title">VR展示</div>
        <div class="desc">高度还原真实环境，身临其境全面看房</div>
        <div class="content">
          <iframe :src="vr" frameborder="0"></iframe>
        </div>
      </div>

      <div class="xingjiabi" :style="{backgroundImage: 'url('+ houseData.costEffective +')'}">
        <van-swipe :autoplay="3000" indicator-color="white" class="banner">
          <van-swipe-item v-for="(img,index) in xingjiabi" :key="index">
            <div class="banner-warp">
              <img :src="img.img" class="banner-img">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="project-warp">
        <div class="page">
          <div class="title">54㎡前后双院</div>
          <div class="desc">普通的高层产品100㎡的住宅，实际的使用面积仅有75㎡左右，小院住宅特有的院+台+阁的设计，赠送面积可达106㎡＞购买面积100㎡。
          </div>
          <div class="project-list">
            <div class="project">
              <img src="../../../assets/images/project/detail/shuang1.png" alt="">
              <div class="desc">室外花园</div>
            </div>
            <div class="project">
              <img src="../../../assets/images/project/detail/shuang2.png" alt="">
              <div class="desc">宠物之家</div>
            </div>
            <div class="project">
              <img src="../../../assets/images/project/detail/shuang3.png" alt="">
              <div class="desc">会客空间</div>
            </div>
            <div class="project">
              <img src="../../../assets/images/project/detail/shuang4.png" alt="">
              <div class="desc">儿童活动</div>
            </div>
          </div>
        </div>

      </div>

      <div class="chewei">
        <img src="../../../assets/images/project/detail/chewei.jpg" alt="">
      </div>

      <div class="kongjian">
        <img src="../../../assets/images/project/detail/ditan.jpg" alt="">
        <div class="title">餐客一体大厅、空间划分灵活</div>
        <div class="desc">
          客餐厅一体化可以减少空间的压抑感和拥挤感。尤其对于小户型来说，空间面积本就局促，客餐厅一体化设计，无论是从客厅还是餐厅来看，都能在视觉上起到一定的通透作用。无论在选材、颜色、搭配上都必须考虑整体的显示效果，这样才能拥有更舒适完美的家居生活。
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/head2022.vue'
import Footer from '@/components/foot2022.vue'

export default {
  name: "productDooryardDetail",
  data () {
    return {
      houseType: 0,
      xingjiabi: [
        {
          img: require("../../../assets/images/project/detail/xjb1.jpg")
        },
        {
          img: require("../../../assets/images/project/detail/xjb2.jpg")
        },
        {
          img: require("../../../assets/images/project/detail/xjb3.jpg")
        }
      ],
    }
  },
  components: {
    Header,
    Footer,
  },
  computed: {
    houseData () {
      let houseData = null
      if (this.houseType) {
        houseData = {
          bannerList: [
            {
              image: require(`../../../assets/images/project/detail/${this.houseType}/banner.jpg`)
            }
          ],
          photoAlbum: [
            {
              image: require(`../../../assets/images/project/detail/${this.houseType}/photoAlbum1.jpg`),
              title: "效果图"
            },
            {
              image: require(`../../../assets/images/project/detail/${this.houseType}/photoAlbum2.jpg`),
              title: "效果图"
            },
            {
              image: require(`../../../assets/images/project/detail/${this.houseType}/photoAlbum3.jpg`),
              title: "效果图"
            },
            {
              image: require(`../../../assets/images/project/detail/${this.houseType}/photoAlbum4.jpg`),
              title: "效果图"
            }
          ],
          costEffective: require(`../../../assets/images/project/detail/${this.houseType}/costEffective.jpg`),
        }
      }
      return houseData
    },
    basicList () {
      let basicList = null
      if (this.houseType == 107) {
        basicList = [
          [
            '楼层结构',
            '2层',

          ],
          [
            '住宅类别',
            '庭院式住宅',
          ],
          [
            '装修情况',
            '按国家交付标准',

          ],
          [
            '产权年限',
            '70年',
          ],
          [
            '容积率',
            '1.2',

          ],
          [
            '绿化率',
            '37.6%',
          ],
        ]
      } else if (this.houseType == 142) {
        basicList = [
          [
            '楼层结构',
            '3层',
          ],
          [
            '住宅类别',
            '庭院式住宅',
          ],
          [
            '装修情况',
            '按国家交付标准',
          ],
          ['产权年限',
            '70年',],
          [
            '容积率',
            '1.2',
          ],
          ['绿化率',
            '37.6%',],
        ]
      } else if (this.houseType == 165) {
        basicList = [
          [
            '楼层结构',
            '3层',
          ],
          ['住宅类别',
            '庭院式住宅',
          ],
          [
            '装修情况',
            '按国家交付标准',

          ],
          [
            '产权年限',
            '70年',
          ],
          [
            '容积率',
            '1.2',

          ],
          [
            '绿化率',
            '37.6%',
          ],
        ]
      } else if (this.houseType == 207) {
        basicList = [
          [
            '楼层结构',
            '3层',
          ],
          [
            '住宅类别',
            '庭院式住宅',
          ],
          [
            '装修情况',
            '按国家交付标准',
          ],
          [
            '产权年限',
            '70年',
          ],
          [
            '容积率',
            '1.2',
          ],
          [
            '绿化率',
            '37.6%',
          ],
        ]
      }
      return basicList
    },
    vr () {
      let vr = ''
      if (this.houseType == 105) {
        vr = 'https://pano8.p.kujiale.com/cloud/design/3FO4BTVSTL3G/show'
      } else if (this.houseType == 138) {
        vr = 'https://pano8.p.kujiale.com/cloud/design/3FO4BJ7SGGHK/show'
      } else if (this.houseType == 163) {
        vr = 'https://pano8.p.kujiale.com/cloud/design/3FO4BJBP4WK6/show'
      } else if (this.houseType == 210) {
        vr = 'https://pano8.p.kujiale.com/design/3FO4B92PYYSN/show'
      }
      return vr
    },
  },
  mounted () {
    this.houseType = this.$route.params.houseType
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 16px;
}
.banner {
  position: relative;
}

.banner > img {
  width: 100%;
}

.banner > .title-warp {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  padding: 0 16px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.banner > .title-warp .area {
  margin-left: auto;
}

.banner > .title-warp .area span {
  font-size: 20px;
  font-weight: 600;
}

.banner > .title-warp .btn {
  font-size: 13px;
  color: #fff;
  background-color: #00cb87;
  border-radius: 100px;
  margin-left: 10px;
  padding: 6px 10px;
  line-height: 13px;
}

.basic {
  padding: 30px 16px;
}

.basic table {
  width: 100%;
  border: 1px solid #dddddd;
  font-size: 13px;
}
.basic table tr {
  height: 38px;
}
.basic table tr th {
  font-weight: 400;
  text-align: left;
  padding: 0 12px;
  background-color: #fcfcfc;
}
.basic table tr td:first-of-type {
  text-align: center;
}
.basic table tr td:last-of-type {
  padding-left: 50px;
  color: #666666;
}

.house-album {
  background-color: #f6f6f6;
  padding: 20px 0 30px;
}
.house-album .title {
  color: #303233;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.house-album .title .more {
  color: #303233;
  font-size: 12px;
}

.house-album .house-list {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.house-album .house-list .item {
  width: 49%;
  margin-top: 11px;
  position: relative;
}
.house-album .house-list .item > img {
  width: 100%;
}
.house-album .house-list .item > .area {
  width: 100%;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 15px;
  line-height: 35px;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
}

.vr {
  background-color: #fff;
}
.vr .title {
  font-size: 19px;
  color: #303233;
  font-weight: 600;
  text-align: center;
  line-height: 19px;
  margin-top: 31px;
}
.vr .desc {
  font-size: 14px;
  color: #999;
  text-align: center;
  width: 50%;
  line-height: 20px;
  margin: 12px auto 10px;
}
.vr .content iframe {
  width: 100%;
  height: 400px;
  display: block;
}

.xingjiabi {
  // background-image: url('../../../assets/images/project/detail/dcxq2.jpg');
  background-size: contain;
  width: 100%;
  height: 480px;
  padding-top: 50px;
}
.xingjiabi .banner {
  width: 280px;
  height: auto;
  margin: auto;
}
.xingjiabi .banner-warp {
  width: 280px;
  height: auto;
}
.xingjiabi .banner-warp img {
  width: 100%;
}

.project-warp {
  padding: 30px 0;
  background-color: #f6f6f6;
}
.project-warp .title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.project-warp .page > .desc {
  font-size: 14px;
  color: #999999;
  margin-top: 22px;
  line-height: 19px;
}

.project-warp .project-list {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.project-warp .project-list .project {
  width: 49%;
  margin-bottom: 11px;
  position: relative;
}

.project-warp .project-list .project > img {
  width: 100%;
}

.project-warp .project-list .project > .desc {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  position: absolute;
}

.chewei {
  width: 100%;
  position: relative;
}
.chewei > img {
  width: 100%;
}
.chewei > .warp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 55px 105px 55px 33px;
  box-sizing: border-box;
}
.chewei > .warp .title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}
.chewei > .warp .desc {
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
}
.kongjian {
  padding: 41px 16px 32px;
}
.kongjian > img {
  width: 100%;
}
.kongjian > .title {
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  margin-top: 30px;
}
.kongjian > .desc {
  font-size: 14px;
  line-height: 20px;
  margin-top: 23px;
}
</style>
